import React from "react"
import Layout from "@components/layout";
import { useStaticQuery, graphql } from "gatsby";

// 言語用設定用ファイル
import { LangSetting } from "@components/language-setting.js";
import langEn from "@content/locales/en/glossary.json";
import langJa from "@content/locales/ja/glossary.json";

// 共通Link用ファイル
import { LinkSetting } from "@components/link-setting.js";
import { SeoContents } from "@components/seo-data.js" // Seo情報設定

const Glossary = ( props ) => {
  
  const data = useStaticQuery(graphql`
  query termPosts {
    termData: allWpTermSetting (sort: {fields: title}) {
      edges {
        node {
          title
          slug
          databaseId
          smartCustomFields {
            termtext
            termtitle
            termslug
            termfurigana
          }
          termSettingCategory {
            nodes {
              slug
              name
              wpParent {
                node {
                  name
                  slug
                }
              }
            }
          }
          termSettingTag {
            nodes {
              slug
            }
          }
        }
      }
    }
    rowData: allWpTermSettingCategory(filter: {parentId: {eq: null}}, sort: {fields: name}) {
      nodes {
        name
        slug
      }
    }
  }
  `
  )
  // 言語設定
  const setLang = LangSetting( props, langEn, langJa ).SetLang;
  const postLang = LangSetting( props, langEn, langJa ).PostLang;

  // リンク設定
  const signPath1 = LinkSetting( setLang ).Register;

  // Seo情報設定
  const pathName = 'glossary'; // ファイル名
  const title = postLang["page-title"];
  const description = postLang["page-description"];
  const keywords = postLang["page-keywords"];
  const seoTag = SeoContents( pathName, setLang, title, description, keywords, "", "" );
  
   // 言語別URL先頭設定
  let langUrl;
  (setLang === 'en') ? langUrl = '/' : langUrl = '/' + setLang + '/';

  // 言語別全データ
  const setData = data.termData.edges.filter(item => item.node.termSettingTag.nodes[0].slug === setLang);
  let setRowData;
  if ( setLang === 'en') {
    setRowData = data.rowData.nodes.filter(item => item.slug === 'en');
  } else {
    setRowData = data.rowData.nodes.filter(item => item.slug !== 'en');
  }

  const addCss = 'mt-16 pb-14 border-b-[#cdd6dd] border-b border-solid' // 調整用CSS
  const setArray = []; // 出力配列
  if ( setLang === 'en') { // 英語データ設定(実装する際にコメントアウト戻す)
    // for (let i = 0; i < 6; i++){ // 5行（4まで増やす）
    //   let setItem1 = [];
    //   for (let j = 0; j < 5; j++){ // 5列区切り
    //     if( i === 5 && j === 2 || i === 5 && j === 3 || i === 5 && j === 4 ) { // 最終行の調整
    //     } else {
    //       let setItem2 = [];
    //       setData[j+i*5].node.smartCustomFields.map((result2) => {
    //         setItem2.push({href: `${langUrl}${pathName}/${setData[j+i*5].node.slug}/${result2.termslug}/`, term: result2.termtitle})
    //       })
    //       setItem1.push(
    //         {   
    //           id: setData[j+i*5].node.slug, 
    //           text: setData[j+i*5].node.slug[0] === "1"? "other": setData[j+i*5].node.slug[0],
    //           class: addCss,
    //           item: setItem2
    //         } 
    //       )
    //     }
    //   }
    //   setArray.push({
    //     row: i ,
    //     data: setItem1
    //   })
    // }
  } else { // 日本語データ設定
    setRowData.forEach((result1) => {
      // 親カテゴリ取得（行名）
      let setRowData = setData.filter(item => {
        return item.node.termSettingCategory.nodes.some(category => {
            return category.wpParent && category.wpParent.node.slug === result1.slug;
        });
      });
      if (result1.slug === 'other') { // otherを抽出
        setRowData = setData.filter(item => item.node.slug === '1-ja');
      }
      // データ形成
      let setItem1 = [];
      for (let i = 0; i < setRowData.length; i++){        
        let setItem2 = [];
        setRowData[i].node.smartCustomFields.forEach((result2) => {
          setItem2.push({href: `${langUrl}${pathName}/${setRowData[i].node.slug}/${result2.termslug}/`, term: result2.termtitle})
        })
        setItem1.push(
          {   
            id: setRowData[i].node.slug, 
            text: setRowData[i].node.termSettingCategory.nodes[0]?.wpParent === null? 
                    setRowData[i].node.termSettingCategory.nodes[1]?.name: 
                    setRowData[i].node.termSettingCategory.nodes[0]?.name,
            class: i === setRowData.length - 1? "": addCss,
            item: setItem2
          } 
        )
      }
      setArray.push({
        row: result1.name,
        data: setItem1
      })
    })
  }

  // データなし行判別用変数
  let checkData = [];
  setArray.forEach((result1) =>{
    result1.data.forEach((result2) => {
      if (result2.item[0].term === ''){
        checkData.push(`${result2.text}行`)
      }
    })    
  })
  

  return (
  <>
    {seoTag}
    <Layout>
      <div className="FV pt-5 pb-8 bg-fixed" langType={setLang}>
        <div className="max-w-xl mx-auto px-6 md:max-w-5xl md:pt-5 pt-2 ">
          <div className=" text-left h1_filed">
            <h1 className="md:text-[60px] text-[35px] font-semibold">{postLang["glossary-h1"]}</h1>
            <div className="text-base max-w-[680px]">
              <p>{postLang["glossary-h1-txt"]}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={`max-w-xl mx-auto  pt-0 md:pt-5 pb-5 md:max-w-5xl px-6 md:mb-5 ${setLang === 'ja'? '':'hidden'}`}>
        <div className="text-left mt-10 md:mt-7 md:mb-10 mb-5">
          <h2 className="font-semibold tracking-wide text-[26px] md:text-[40px]">{postLang["glossary-h2"]}</h2>

          {/* 表 */}
          <div class={`grid mt-4 md:grid-cols-[1fr_1fr] grid-rows-[repeat(6,auto)] md:grid-rows-[repeat(5,auto)] ${setLang === 'en'? "gap-[6px_6px] ": "gap-[6px_42px] md:grid-flow-col"}`}>
            {setArray.map((result1) => {
              let item = result1.data.map((result2) => {
                return <>
                  <a className={`relative grid place-content-center h-12 text-inherit border transition-[0.25s] duration-[ease] delay-[0s] transition-[border]
                     no-underline rounded-[3px] border-solid border-[#cbd4da] bg-[#f2f5f8] ${result2.item[0].term === ''? "pointer-events-none opacity-40": ""}`}
                    data-count="0" data-scroll="" href={`#${result2.id}`}>{result2.text}
                  </a>
                </>
              })
              if ( result1.row !== '英字' ) {
                return <>
                  <div className={`grid gap-1.5 items-center ${setLang === 'en'? "grid-cols-[repeat(5,1fr)]": "grid-cols-[repeat(6,1fr)]"}`}>
                    <div className={`${setLang === 'en'? "hidden": ""}`}>{result1.row}</div>
                    {item}
                  </div>
                </>
              } else {
                return <></>
              }
            })}
          </div>
          <div class={`grid md:grid-cols-[1fr_1fr] grid-rows-[repeat(6,auto)] md:grid-rows-[repeat(5,auto)] ${setLang === 'en'? "gap-[6px_6px] ": "gap-[6px_42px] md:grid-flow-col"}`}>
            <div className={`grid grid-cols-[repeat(6,1fr)] gap-1.5 items-center row-[6/7] col-[1/2] ${setLang === 'en'? "hidden": ""}`}>
                <div className="">英字</div>
                <a className="relative grid place-content-center h-12 text-inherit border col-[2/4]
                      transition-[0.25s] duration-[ease] delay-[0s] transition-[border] no-underline rounded-[3px] border-solid border-[#cbd4da] bg-[#f2f5f8]" 
                      data-count="30" data-scroll="" href="#1-ja">A〜Z</a>
            </div>
          </div>

          {/* 一覧 */}
          <div>
            {setArray.map((result1) =>{
              return <>
                <h2 class={`font-bold text-[28px] border-b-[#FE0000] mt-14 pt-0 pb-2.5 px-0 border-b-[1px] border-solid ${setLang === 'en'? "hidden": ""} ${checkData.includes(result1.row)? "hidden": ""}`}>{result1.row}</h2>
                {result1.data.map((result2) => {
                  return <>
                    <div class={`grid grid-cols-[100px_1fr] pt-16 pb-0 px-0 ${result2.class} ${result2.item[0].term === ''? "hidden": ""}`} id={result2.id}>
                      <h3 class="font-bold text-2xl leading-7">{result2.text}</h3>
                      <ul class="grid grid-cols-[repeat(auto-fit,minmax(230px,1fr))] gap-2 list-none">
                        {result2.item.map((result3) => {
                          return <>
                            <li class="">
                              <a class="inline-flex items-baseline gap-2 no-underline before:content-['＞'] before:text-[#067CFA] before:text-[13px] before:font-bold  before:w-[13px] before:h-[0.75em]" 
                                 href={`${result3.href}`}>{result3.term}</a>
                            </li>
                          </>
                        })}
                      </ul>
                    </div>
                  </>
                })}   
              </>
            })}
          </div>


        </div>
      </div>

      {/* 口座開設ボタン */}
      <div className="Feature bg-fixed">
        <div className="mt-11 py-14 py-14  md:py-10 md:py-10"> 
          <div className="md:mx-auto max-w-5xl flex justify-center">
            <a href={signPath1} className="cont_regi01 rounded block md:max-w-xs mx-5  text-center text-white font-bold  px-16 my-2 bg-black hover:scale-[1.05] pt-[18px] pb-[20px] duration-300">{postLang["open"]}</a>
          </div>
        </div>
      </div>
    </Layout>
  </>
  )
}
export default Glossary